@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Hello';
  src: local('Hello'), url('./fonts/Hello.ttf') format('truetype');
}

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --default-cell-bg-color: theme('colors.white');
  --default-cell-border-color: theme('colors.black');
  --default-cell-text-color: theme('colors.black');
  --absent-cell-bg-color: theme('colors.slate.400');
  --correct-cell-bg-color: theme('colors.green.400');
  --present-cell-bg-color: theme('colors.yellow.400');
  --cl__white: #ffffff;
  --cl__off-white: #f4f4f4;
  --cl__black: #000000;
  --cl__black--rgb: 0, 0, 0;
  --cl__governor-bay: #2f30bf;
  --cl__persian-blue: #2d38dd;
  --cl__cornflower-blue: #6589eb;
  --cl__cornflower-blue--two: #6398f5;
  --cl__cornflower-blue--three: #3695d9;
  --cl__portage: #7794eb;
  --cl__cornflower: #87c0e8;
  --cl__french-pass: #c2e3fe;
  --cl__pattens-blue: #d4eafe;
  --cl__link-water: #ecf5fa;
  --cl__jagged-ice: #c6ede3;
  --cl__mint-tulip: #c4f1e7;
  --cl__polar: #d6f5ee;
  --cl__tea-green: #d4eeba;
  --cl__spring-sun: #f5ffdf;
  --cl__orinoco: #e5fbc9;
  --cl__aqua-forest: #5b9f77;
  --cl__aqua-forest--rgb: 91, 159, 119;
  --cl__amazon: #226252;
  --cl__corduroy: #5a6c69;
  --cl__perfume: #dcd2fb;
  --cl__scorpion: #5b5b5b;
  --cl__mule-fawn: #9b5e33;
  --cl__buff: #f1dc86;
  --cl__wewak: #f09aae;
  --cl__chinook: #a0e3b6;
  --cl__riptide: #77e0c7;
  --cl__clear-day: #e9fffa;
  --cl__blue: #82c9ff;
  --cl__dark-blue: #060821;
  --cl__purple: #5a5a87;
  --cl__brown: #9c5d32;
  --cl__light-burgundy: #97394e;
  --cl__dusk: #434365;
  --cl__iron: #e6e7e8;
  --cl__manatee: #9e9e9f;
  --cl__mulled-wine: #4d3f61;
  --cl__martinique: #3e3152;
  --cl__sail: #ade0f9;
  --cl__riptide: #77e0c7;
  --cl__dusk-two: #52527c;
  --cl__dusk-three: #5a5a87;
  --cl__slate-blue: #69699b;
  --cl__scampi: #5e5ea0;
  --cl__fiord: #4c4c77;
  --cl__fiord--two: #43436d;
  --cl__athens-grey: #f0f0f4;
  --cl__east-bay: #4e4e7a;
  --cl__error: #f86468;
  --cl__success: #77e0c7;
  --cl__malibu: #6bc0fd;
  --cl__charlotte: #c4e9fb;
  --cl__royal-blue: #3862e2;
  --cl__royal-blue--two: #3b65e3;
  --cl__royal-blue--three: #355ee0;
  --cl__comet: #5a5a87;
  --cl__comet--two: #52527f;
  --cl__sky-blue: #c2e3f3;
  --cl__moon: #d8d7f8;
  --cl__mint: #cbf3ea;
  --cl__curious-blue: #2f8ad4;
  --cl_background-color: var(--cl__french-pass);
  --cl__menu-item-background--active: var(--cl__athens-grey);
  --cl__menu-item-foreground--active: var(--cl__comet);
  --cl__menu-item-background--hover: var(--cl__comet);
  --cl__menu-item-foreground--hover: var(--cl__white);
  --cl__footer-background: var(--cl__purple);
  --cl__footer-button--background: var(--cl__white);
  --cl__footer-subscribe-button--background: var(--cl__sail);
  --cl__footer-button--foreground: var(--cl__comet);
  --cl__footer-button--focus: var(--cl__charlotte);
  --cl__footer-button--hover: var(--cl__charlotte);
  --cl__footer-button--active: var(--cl__charlotte);
  --cl__primary-header: var(--cl__dusk-three);
  --cl__secondary-header: var(--cl__brown);
  --cl__body-copy: var(--cl__dusk-three);
  --cl__primary-button--background: var(--cl__comet);
  --cl__primary-button--foreground: var(--cl__white);
  --cl__primary-button--focus: var(--cl__manatee);
  --cl__primary-button--hover: var(--cl__east-bay);
  --cl__primary-button--active: var(--cl__fiord--two);
  --cl__cookie-message--background: var(--cl__dusk-two);
  --cl__cookie-message--foreground: var(--cl__white);
  --cl__cookie-message--copy: var(--cl__riptide);
  --cl__button--background: var(--cl__riptide);
  --cl__post-grid-counters: var(--cl__comet);
  --cl__post-card--title-hover: var(--cl__scampi);
}

.dark {
  --default-cell-bg-color: theme('colors.slate.900');
  --default-cell-border-color: theme('colors.white');
  --default-cell-text-color: theme('colors.white');
  --absent-cell-bg-color: theme('colors.slate.700');
}

.high-contrast {
  --correct-cell-bg-color: theme('colors.orange.400');
  --present-cell-bg-color: theme('colors.cyan.400');
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

svg.cursor-pointer {
  transition: all var(--animation-speed-fast);
}

svg.cursor-pointer:hover {
  transform: scale(1.2);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

.navbar {
  margin-bottom: 2%;
}

.navbar-content {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
}

.right-icons {
  display: flex;
}

@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Date picker */

.react-datepicker {
  @apply h-72;
}

.react-datepicker__input-container input {
  @apply form-input block w-full rounded border border-gray-300 bg-white text-base shadow-sm md:text-sm;
}

.react-datepicker-popper {
  @apply z-40 w-72 rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute top-12 right-0 z-10 w-72 transform-none rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800 dark:text-gray-100;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-center text-xs font-medium text-gray-400 dark:text-gray-500;
}

.react-datepicker__day-name {
  @apply flex h-8 w-8 items-center justify-center rounded-full py-1;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded py-1 text-sm leading-loose text-gray-700 transition dark:text-gray-200;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300 dark:text-gray-600;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-indigo-200;
}

.react-datepicker__day--selecting-range-start {
  @apply border-2 border-indigo-600 bg-white;
}

.react-datepicker__day--selecting-range-end {
  @apply border-2 border-indigo-600 bg-white;
}

.react-datepicker__day--selected {
  @apply bg-indigo-600 text-white dark:text-white;
}

.react-datepicker__day--range-start {
  @apply bg-indigo-600 text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__day--range-end {
  @apply bg-indigo-600 text-white hover:bg-white hover:text-gray-700;
}
